import React from 'react';
import { graphql } from 'gatsby';
import BasicPage from '../templates/Page';
import { withPreview } from 'gatsby-source-prismic';

const EquipmentPage = ({ data }) => {
  const title = 'Equipment';
  const image = 'https://jeanlucponty.cdn.prismic.io/jeanlucponty/a243727b4372bd4c388ca24403a06d5ecbe624ad_instruments.jpg';
  const description = "Learn about JLP's equipment - Violins: ZETA is active again, the 'Jean Luc Ponty Signature' model is now available as well as Jazz Fusion models...";

  return (
    <BasicPage
      data={data}
      title={title}
      image={image}
      description={description}
    />
  );
};

export default withPreview(EquipmentPage);

export const query = graphql`
  query {
    prismicBasicPage(
      prismicId: {
        eq: "W2854igAAMfICgqo"
      }
    ) {
      prismicId
      data {
        page_title {
          html
          text
        }
        body {
          __typename
            ...on PrismicBasicPageBodyText {
              id
              slice_type
              primary {
                text {
                  html
                }
              }
            }
            ...on PrismicBasicPageBodyImageWithCaption {
              id
              slice_type
              items {
                illustration {
                  url
                }
              }
            }
        }
      }
    }
  }
`;
